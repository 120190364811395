/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Layout from '../layouts/Landing';
import BlogRoll from '../components/BlogRoll/BlogRoll';

import { HTMLContent } from '../components/Content';
import Section from '../components/Section';

export const IndexPageTemplate = ({
  classes,
}) => (
  <div>
    <Section className={classes.section}>
      <Box mb={3} fontSize={40} fontWeight="bold">
        Latest stories
      </Box>
      <BlogRoll />
      <Box display="flex" justifyContent="center" mt={6}>
        <Link to="/blog">
          <Button variant="outlined" color="primary">Read more</Button>
        </Link>
      </Box>
    </Section>
  </div>
);

IndexPageTemplate.propTypes = {
  classes: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.mdx;
  return (
    <Layout
      contentComponent={HTMLContent}
      hero={frontmatter.hero}
    >
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    mdx(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          title
          description
          cta1
          cta2
        }
      }
    }
  }
`;
