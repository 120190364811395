/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Common from './Common';
import Hero from '../components/Hero/Hero';

const TemplateWrapper = ({ hero, contentComponent, children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(() => {
      setIsLoggedIn(true);
    })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Common>
      <Hero {...hero} contentComponent={contentComponent} isLoggedIn={isLoggedIn} />
      <div>{children}</div>
    </Common>
  );
};

export default TemplateWrapper;
